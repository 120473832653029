<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 36"
    fill="none"
  >
    <path
      d="M5 7.5V6C4.60218 6 4.22064 6.15804 3.93934 6.43934C3.65804 6.72064 3.5 7.10218 3.5 7.5H5ZM32 7.5H33.5C33.5 7.10218 33.342 6.72064 33.0607 6.43934C32.7794 6.15804 32.3978 6 32 6V7.5ZM5 9H32V6H5V9ZM30.5 7.5V25.5H33.5V7.5H30.5ZM29 27H8V30H29V27ZM6.5 25.5V7.5H3.5V25.5H6.5ZM8 27C7.60218 27 7.22064 26.842 6.93934 26.5607C6.65804 26.2794 6.5 25.8978 6.5 25.5H3.5C3.5 26.6935 3.97411 27.8381 4.81802 28.682C5.66193 29.5259 6.80653 30 8 30V27ZM30.5 25.5C30.5 25.8978 30.342 26.2794 30.0607 26.5607C29.7794 26.842 29.3978 27 29 27V30C30.1935 30 31.3381 29.5259 32.182 28.682C33.0259 27.8381 33.5 26.6935 33.5 25.5H30.5Z"
    />
    <path
      d="M5 7.5L18.5 21L32 7.5"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
